<template>
  <div>
    <router-link></router-link>
    <!-- <router-link to="/">隐私政策</router-link>
    <router-link to="/UserAgreement">用户协议</router-link>
    <router-link to="/MobileTerms">中国移动认证服务条款</router-link> -->
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
const router = useRouter();
</script>
<style scoped>
div {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  display: flex;
  justify-content: space-around;
}
</style>
