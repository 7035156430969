<template>
  <div class="info">
    <h2>《隐私政策》</h2>
    <p style="font-weight: 600">特别提示：</p>
    <p>
      感谢您对厦门网案科技股份有限公司（以下简称“网案”）的信任，网案深知个人信息及隐私对您的重要性，故网案将采取相应安全措施并按照法律法规要求保护您的个人信息。鉴于此，网案制定《隐私政策》（以下简称“本政策/本隐私政策”）并提示您：
    </p>
    <p>
      本政策由网案与您共同签订，具有合同法律效力，自您依照提示点击“同意”和/或“注册”和/或“登录”后，即您签署后，对您发生法律约束力。
    </p>
    <p>
      本政策适用于您与网案签订的《服务协议》中所述的服务，即：网案提供的网案教学信息系统服务、网案为您提供的其他服务（以下简称“本服务”）。
    </p>
    <p>
      若网案或网案关联公司的其他产品或服务中使用了网案教学信息系统提供的产品或服务（例如直接使用本服务中的账户登录）但未设独立隐私政策的，则本政策同样适用于该产品或服务。
    </p>
    <p>
      本政策不适用于其他通过网案的服务而接入的第三方服务，上述第三方包括但不限于您的交易相对方、任何第三方网站以及第三方服务提供者，网案对前述第三方使用您个人信息的行为及后果不承担任何责任，具体规定您可参照该第三方的隐私政策或相关声明。
    </p>
    <p>
      在您使用网案所提供的各项产品及服务前，请您务必审慎阅读并充分理解本政策各条款内容，本政策中相关重点内容网案已将字体加粗进行标注以提示您注意，一旦您使用或继续使用网案的产品或服务，即表示您已同意网案按照本政策收集、处理、使用、储存、分享您的相关信息。
    </p>
    <p>
      如您对本政策有任何疑问、不理解、不认同，都应立即终止签署《隐私政策》。
    </p>
    <p>如您对本政策或相关事宜有任何问题，您可通过客服与网案联系。</p>
    <p>本政策将帮助您了解以下内容：</p>
    <p>一、网案如何收集和使用您的个人信息</p>
    <p>二、网案如何使用Cookie和同类技术</p>
    <p>三、网案如何共享、转让、公开披露您的个人信息</p>
    <p>四、网案如何保护您的个人信息</p>
    <p>五、您的权利以及权利行使</p>
    <p>六、网案如何处理未成年人的个人信息</p>
    <p>七、个人信息的跨界传输</p>
    <p>八、本政策的更新</p>
    <p>九、如何联系网案</p>
    <p>一、网案如何收集和使用您的个人信息</p>
    <p>
      网案会遵循正当、合法、必要的原则，出于本政策所述以下目的，收集和使用您的个人信息，网案通过本政策向您明示网案收集、使用个人信息的目的、方式和范围，同时网案会采取合理可行的措施，不会收集与本服务无关的个人信息。
    </p>
    <p>（一）注册成为网案用户：</p>
    <p>
      您在注册和/或登陆本系统时需提供您的手机号码等信息；网案在审核您的信息时需要您提供诸如真实姓名、性别、出生年月日、居住地、电子邮箱等信息；您在使用本系统过程中，根据系统提示自身不断完善补全个人资料等信息。如果您仅需使用浏览、搜索等基本服务，您不需要提供上述信息。在您主动注销账号时，网案将根据法律法规的要求尽快删除您的用户信息。
    </p>
    <p>（二）为您提供、推送信息及服务</p>
    <p>
      网案收集用户信息将用于向您展示服务，提供服务，推送产品，身份验证、检测，评估、规避、处置交易风险，信息识别，信息分析，信息结合分析，信息保存，信息披露，与可信赖合作伙伴、网案关联公司的信息共享与处理，安全保障、交易风险的规避与处置、其他用途。
    </p>
    <p>（三）网案还将通过以下方式收集您的信息：</p>
    <p>
      1.网络推广：为了您更好的了解认识网案，网案将会通过网络平台（微博、头条、微信等）合法进行网络推广，您在通过其他平台关于网案的链接而自主录入的用户信息。
    </p>
    <p>
      2.其他推广：为了您更好的了解认识网案，网案将会通过线下机构合法进行推广，您在与网案合作的线下推广机构中自主留存的信息。
    </p>
    <p>
      3.本系统接入的三方服务：网案为了您更好的体验和使用本系统，网案将会合法接入第三方服务机构的服务，包含但不限于：支付宝支付、微信支付等支付产品。在您自主选择上述服务中，需要提供必要的个人资料，方能使用第三方服务机构的产品。基于网案与第三方服务机构完成商业合作的行为中包括但不限于确认交易状态、确认账款等、第三方服务机构的承诺包括但不限于获得您的授权等及为您提供您自主选择的服务的考虑，网案与第三方服务机构的合作协议中可能会约定：各方对依法依约提取、保存的用户信息进行共同校对、信息共享的条款。网案将通过第三方服务机构的信息共享而获取您的信息，否则网案与第三方服务机构将无法完成整个交易，进而您也无法使用第三方服务机构的产品。您注意，鉴于网案对契约的遵守及对第三方服务机构的合理信赖，网案依法无须对第三方服务机构共享信息是否获得您的授权而负有审查义务，但网案会了解个人信息提供方已获得的个人信息处理的授权同意范围，包括使用目的，个人信息主体是否授权同意共享等。如果第三方服务机构向网案承诺获得您的授权并共享了您的信息但实际没有获得您的合法授权，您可随时告知网案，网案将立即停止使用并删除您的信息。
    </p>
    <p>
      4.信息结合：网案为了为向您提供更契合您需求的学习报告、页面等信息，本系统会不断升级，同时网案会收集您使用本系统过程中的留存的信息并将这些信息进行关联，这些信息可能会有：设备信息、日志信息等包括但不限于设备型号、操作系统版本、IP地址、使用的语言、访问日期等。网案会对这些非自然人身份的信息单独进行识别分析，或将其与用户信息进行结合识别分析，但不会篡改信息。
    </p>
    <p>
      5.通信/电话收集：在您向网案咨询、投诉等的通信/通话沟通中，或网案提供服务而进行的通信/通话回访中，为了满足您的需求而进行的必要的信息收集，同时网案可能会保存您的通信/通话记录和内容。
    </p>
    <p>（四）改进网案产品及服务</p>
    <p>
      为改善网案的产品或服务、向您提供个性化的信息搜索及交易服务，网案会根据您的浏览记录、设备信息、位置信息、订单信息，提取您的浏览、搜索偏好、行为习惯、位置信息等特征，基于特征标签向您展示、推送信息
    </p>
    <p>（五）其他用途</p>
    <p>
      网案将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他用途时，会事先征求您的同意；或将该用途载明于更新后的《隐私政策》。
    </p>
    <p>（六）征得授权同意的例外</p>
    <p>以下情形中，网案收集您的个人信息无需征得您的授权同意：</p>
    <p>1、与国家安全、国防安全有关的；</p>
    <p>2、与公共安全、公共卫生、重大公共利益有关的；</p>
    <p>3、与犯罪侦查、起诉、审判和判决执行等有关的；</p>
    <p>
      4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
    </p>
    <p>5、所收集的个人信息是您自行向社会公众公开的；</p>
    <p>
      6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
    </p>
    <p>7、根据个人信息主体要求签订和履行合同所必需的；</p>
    <p>
      8、用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；
    </p>
    <p>9、个人信息控制者为新闻单位且其在开展合法的新闻报道所必需的；</p>
    <p>
      10、个人信息控制者为学术研究机构，出于公共利益开展统计或学术研究必要且其对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
    </p>
    <p>11、法律法规规定的其他情形。</p>
    <p>
      如网案停止运营本系统，网案将及时停止继续收集您的信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，对所持有的个人信息进行删除或匿名化处理。
    </p>
    <p>
      您知悉，无法与任何特定个人直接建立联系的数据，不属于个人信息。如果网案将无法与任何特定个人建立联系的数据与其他信息结合用于识别自然人个人身份，或者将个人信息结合使用，则在结合使用期间，此类信息将被视为个人信息。
    </p>
    <p>二、网案如何使用Cookie和同类技术</p>
    <p>（一）Cookie的使用</p>
    <p>
      Cookie是由网页服务器存放在您的访问设备上的文本文件，指定给您的Cookie是唯一的，它只能被将Cookie发布给您的域中的Web服务器读取。
    </p>
    <p>
      为确保网站正常高效运行、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，网案可能会在您的计算机或移动设备上储存名为Cookie的数据文本文件。Cookie会帮助您再后续访问网案网站时调用您的信息，简化您填写个人信息（例如一键登录等）的流程、为您提供服务/产品选择的偏好设置、帮助您优化广告的选择与互动、保护您的数据安全等。网案不会将Cookie用于本政策所述目的之外的任何用途，您可根据您自身的偏好管理或删除Cookie。
    </p>
    <p>
      您可以清除计算机或移动设备上保存的所有Cookie，您有权接受或者拒绝Cookie。大多数浏览器会自动接受Cookie，但您通常可根据自己的需求来修改浏览器的设置以拒绝Cookie。另外您也可以随时清除软件内保存的所有Cookie。但您可能将因此无法完全体验我们某些便捷性和安全性的服务功能。
    </p>
    <p>（二）网络Beacon的使用</p>
    <p>
      除Cookie外，网案网页上可能会包含一些电子图像（称为“单像素”GIF文件或网络Beacon），它们可以帮助网站计算游览网页的用户或访问某些Cookie。网案可能通过在网站上使用网络Beacon,计算用户访问数量，并通过访问Cookie辨认注册用户。
    </p>
    <p>三、网案如何共享、转让、公开披露您的个人信息</p>
    <p>（一）共享</p>
    <p>
      网案收集您的个人信息原则上是不会进行共享，不会对本服务提供者以外的公司、组织和个人共享您的个人信息，但以下情况除外：
    </p>
    <p>
      1、在获取明确同意的情况下共享：获得您的明确同意后，网案会与其他方共享您的个人信息。
    </p>
    <p>
      2、在法定情形下的共享：网案可能会根据法律法规、诉讼争议解决，或按行政、司法机关要求，对外共享您的个人信息。
    </p>
    <p>
      3、与关联公司间共享：为了网案更好的为您提供服务、关联公司通过您的账号或关联账号为您提供服务、保护关联公司及其用户的人身财产安全免遭侵害，您的个人信息可能会与网案的关联公司共享。网案只会共享必要的个人信息，如果网案共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。
    </p>
    <p>
      4、与授权合作伙伴共享：为实现网案为您提供服务的目的，网案所提供的服务由授权服务方与网案共同为您提供，为了网案能为您提供更好的客户服务，网案提供的服务链接中有该第三方公司提供的产品，网案可能与合作伙伴共享您的某些个人信息，以便提供更好的用户体验。例如，您选择的分期付款产品时，录入的个人信息不完整、不准确，导致身份校验困难，网案将会与合作伙伴共享您的个人信息，从而避免您的再次录入和使用产品的延迟。网案仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。网案的合作伙伴无权将共享的个人信息用于与产品或服务无关的其他用途，同时网案会要求合作伙伴采取至低不低于网案采取的保密、安全措施保护您的个人信息。
    </p>
    <p>5、网案合作伙伴及共享的方式可能包括以下类型：</p>
    <p>
      （1）本系统设计、研发的供应商：网案与该类合作伙伴共享您的个人信息是为了分析、设计、研发、升级本系统，而不是通过供应商商识别、联系您个人。
    </p>
    <p>
      （2）围绕本系统的服务提供商：网案与该类合作伙伴共享您的个人信息是为了与服务提供商共同为您提供服务，并且该类服务提供商提供的产品是您先期自主的选择。
    </p>
    <p>
      （3）广告、分析服务类的合作伙伴：网案将会通过去标识化且无法识别某特定个人的操作，与该类合作伙伴共享您的信息，帮助他们了解网案的受众群体和用户。
    </p>
    <p>
      （4）学术或政府的研究机构：网案出于公共利益的目的，该类机构对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理时，网案将为该类机构提供用于统计或学术研究的必要信息。
    </p>
    <p>
      （5）其他可信赖的授权的合作伙伴：若有其他新增授权合作伙伴，网案会将其载明于更新后的《隐私政策》中。
    </p>
    <p>6、网案共享您的个人信息时还会做到：</p>
    <p>
      （1）事先展开个人信息安全影响的评估，并依评估结果采取有效的保护个人信息主体的措施；
    </p>
    <p>
      （2）向个人信息主体告知共享个人信息的目的、数据接收方的类型，并事先征得个人信息主体的授权同意。共享去标识化处理的个人信息，且确保数据接收方无法重新识别个人信息主体的除外；
    </p>
    <p>
      （3）共享个人敏感信息前，除（2）中告知的内容外，还应向个人信息主体告知涉及的个人敏感信息的类型、数据接收方的身份和数据安全能力，并事先征得个人信息主体的明示同意；
    </p>
    <p>
      （4）准确记录和保存个人信息的共享的情况，包括共享的日期、规模、目的，以及数据接收方基本情况等；
    </p>
    <p>（5）承担因共享个人信息对个人信息主体合法权益造成损害的相应责任；</p>
    <p>
      （6）帮助个人信息主体了解数据接收方对个人信息的保存、使用等情况，以及个人信息主体的权利，例如：访问、更正删除、注销账户等。
    </p>
    <p>本政策自您签署生效后，即代表您同意并授权网案依照本政策进行信息共享。</p>
    <p>（二）转让</p>
    <p>网案不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
    <p>
      1、在获取明确同意的情况下转让：获得您的明确同意后，网案会向其他方转让您的个人信息；
    </p>
    <p>
      2、在本服务提供者发生收购、兼并、重组或破产清算情形，或其他涉及收购、兼并、重组或破产清算情形时：如涉及到个人信息转让，网案会向个人信息主体告知有关情况；网案会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则网案将要求该公司、组织重新获得您的授权同意，同时该公司、组织应重新获取您的明示同意。
    </p>
    <p>本政策自您签署生效后，即代表您同意并授权网案依照本政策进行信息转让。</p>
    <p>（三）公开披露</p>
    <p>
      网案不会公开披露您的个人信息，但网案经法律授权或具备合理事由确需公开披露的情形除外：
    </p>
    <p>
      1、在获取明确同意的情况下披露：获得您的明确同意后，网案会披露您的个人信息。
    </p>
    <p>
      2、在法定情形下的披露：网案可能会根据法律法规、行政、司法机关要求，披露您的个人信息。
    </p>
    <p>
      3、在严重违反本系统相关协议规则情况下披露：若您严重违反本系统相关的协议规则，侵害了网案、网案关联公司、本系统用户或公众的人身和/或财产权益，为了避免他人合法权益继续遭到侵害，网案在接到投诉后，可能会根据本系统的协议规则征得您同意的情况下披露您的个人信息。例如，若您在履行合同义务过程中违法犯罪，网案可能会公开披露您的若干个人信息和网案对您的处罚。
    </p>
    <p>4、网案披露您的个人信息时还会做到：</p>
    <p>
      （1）事先展开个人信息安全影响的评估，并依评估结果采取有效的保护个人信息主体的措施；
    </p>
    <p>
      （2）向个人信息主体告知公开披露个人信息的目的、类型，并事先先征得个人信息主体的明示同意。
    </p>
    <p>
      （3）公开披露个人敏感信息前，除（2）中告知的内容外，还应向个人信息主体告知涉及的个人敏感信息的内容；
    </p>
    <p>
      （4）准确记录和保存个人信息的公开披露的情况，包括公开披露的日期、规模、目的、公开范围等；
    </p>
    <p>（5）承担因公开披露个人信息对个人信息主体合法权益造成损害的相应责任；</p>
    <p>（6）不得公开披露个人生物识别信息。</p>
    <p>
      本政策自您签署生效后，即代表您同意并授权网案依照本政策进行信息公开披露。
    </p>
    <p>5.共享、转让、公开披露个人信息时事先征得授权同意的例外</p>
    <p>
      以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：
    </p>
    <p>（1）与国家安全、国防安全有关的；</p>
    <p>（2）与公共安全、公共卫生、重大公共利益有关的；</p>
    <p>（3）与犯罪侦查、起诉、审判和判决执行等有关的；</p>
    <p>
      （4）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
    </p>
    <p>（5）您自行向社会公众公开的个人信息的；</p>
    <p>（6）从合法公开披露</p>
    <p>四、网案如何保护您的个人信息</p>
    <p>
      （一）为保障您的信息安全，网案努力采取各种符合业界标准的物理、电子和管理方面的安全措施来保护您的个人信息，防止您的个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。网案会采取一切合理可行的措施，保护您的个人信息。例如在您的浏览器与“服务”之间交换数据（如信用卡信息）时采用SSL加密保护技术；同时对网站本身提供https安全浏览方式；使用加密技术确保数据的保密性、安全性；使用受信赖的保护机制防止数据遭到恶意攻击；部署访问控制机制，确保只有授权人员才可访问个人信息；以及举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
    </p>
    <p>
      （二）网案为实现本服务、本政策所述之目的所必需的最短时间内保存您的个人信息；超出保存期限后，网案会对个人信息进行删除或匿名化处理。除非为了您的利益考虑需要延长保留期或受到法律的允许。
    </p>
    <p>
      （三）若您发现网案违法或违约收集、使用您的个人信息和/或搜集的您的个人信息有错误的，在您要求网案删除和或更正您的个人信息时，网案会采取措施予以删除或更正。同时您可以通过本政策和/或其他方式向您公布的联系方式向网案客服投诉、举报、反映信息安全问题。
    </p>
    <p>
      （四）您知悉并理解，互联网并非绝对安全的环境，网案将尽力确保您发送给网案的任何信息的安全性，但同时网案强烈建议您通过安全方式登录、使用负责密码、协助网案保障您账号安全，当您发现您的账户或密码发生泄漏情况，请立即通过本政策和/或其他方式向您公布的联系方式联络网案，以便网案采取必要的措施。
    </p>
    <p>
      （五）网案针对网络安全事件制定了应急预案，在发生网络安全事件时，会立即启动应急预案，采取补救措施，及时处置系统漏洞、计算机病毒、网络攻击、网络入侵等安全风险。
    </p>
    <p>
      （六）在不幸发生个人信息安全事件后，网案将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、网案已采取或将要采取的处置措施、您如何自主防范和降低风险的建议、对您的补救措施等。事件的相关情况网案将以邮件、信函、电话、推送、公告等方式告知您，当难以注意告知个人信息主体时，网案会采取合理、有效的方式发布公告。
    </p>
    <p>同时网案会依法向有关主管部门主动报告安全事件的处置情况。</p>
    <p>五、您的权利以及权利行使</p>
    <p>（一）信息访问 您可以通过如下方式访问下列信息：</p>
    <p>
      网案持有您的个人信息或类型：您可通过网案家教APP客户端、微信小程序里各功能等功能模块查看您的个人信息或类型，包括但不限于跟人学生信息、成绩、课程计划等。
    </p>
    <p>
      您可通过网案家教APP客户端、微信小程序各功能模块访问您的个人信息或类型。
    </p>
    <p>
      个人信息的来源、所用于的目的：您可通过网案家教APP客户端、微信小程序的“我的资料-《隐私政策》”访问查看您个人信息的来源、所用于的目的。
    </p>
    <p>获得您个人信息的第三方身份或类型：</p>
    <p>
      您可通过网案家教APP客户端、微信小程序（家长版/老师版）的“《隐私政策》”访问查看获得您个人信息的第三方身份或类型；如您觉得本政策载明的不够清晰，您可通过客服提出您的诉求，网案将会更为准确的给您另行提供获得您个人信息的第三方身份或类型。
    </p>
    <p>
      如果您无法通过上述路径访问该等个人信息，您可以随时通过客服与网案联系，网案将会另行回复您的访问请求。
    </p>
    <p>
      如果您提出访问非您主动提供的个人信息时，网案有权作出是否响应的决定，并给出解释说明。
    </p>
    <p>（二）信息删除</p>
    <p>发生以下情形时，在您要求网案删除您的个人信息时，网案会及时删除：</p>
    <p>1、网案违反法律法规收集、使用您的个人信息的；</p>
    <p>2、网案违反约定收集、使用您的个人信息的；</p>
    <p>3、网案违反法律法规、违反约定向第三方共享、转让您的个人信息的；</p>
    <p>4、网案违反法律法规、违反约定公开披露您的个人信息的；</p>
    <p>
      5、网案不再运营本系统、永久不再为您提供本服务且未能依法依约转让您的个人信息的；
    </p>
    <p>6、您向网案主动申请注销您的账号的。</p>
    <p>
      以上事实被证明，网案决定响应您的请求时，在本条第3项、第4项或第6项情况下，网案除了删除您的个人信息外，网案同时会立即停止共享、转让的行为，并通知第三方及时删除；网案同时会立即停止公开披露的行为，并发布通知要求相关接收方删除相应的信息，除非法律法规另有规定，或这些主体已获得您的独立授权。
    </p>
    <p>
      在您主动注销账户之后，网案将停止为您提供产品或服务，根据相关法律的要求删除您的个人信息或对其进行匿名化处理。
    </p>
    <p>
      当您从网案的服务中删除信息后，网案可能不会即时从备份系统中删除相应信息，但网案会在备份更新时对上述信息进行删除。
    </p>
    <p>（三）撤回同意</p>
    <p>
      在网案依法依约收集、使用、共享、转让、披露您的个人信息时和/或后，您随时都可通过客服向网案提出撤回收集、使用、共享、转让、披露个人信息的同意授权，撤回授权的意思表示（生效书面文件）送达后，网案后续将不会再处理相应的个人信息。
    </p>
    <p>但您撤回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。</p>
    <p>（四）约束信息系统自动决策</p>
    <p>
      本系统若干功能的实现，可能会根据信息系统、算法等自动做出决策，该种决策的自动做出显著影响您的权益时，您可随时通过客服向网案提出申诉。
    </p>
    <P>（五）响应您的请求</P>
    <P
      >您向网案提出本政策第五条第（一）款至第（四）款或其他请求时，应向网案提出书面请求和提交您的身份证明。待网案验证您的身份后，网案将在三十天内或法律法规规定的期限内做出答复及处理，若网案无法处理您的请求将给与您合理解释。</P
    >
    <P
      >对合理的请求原则上不收费，但对一定时期内多次重复的请求，可视情况收取一定成本费用。</P
    >
    <P
      >如直接实现您的请求需要付出高额的成本或存在其他显著的困难，网案会向您提供其他替代性方法，该方法包括但不限于满足您需求的技术开发方案，但网案会向您收取合理的费用。对于无端重复、需要过多技术手段、给他人合法权益带来风险或非常不切实际的请求，网案可能会予以拒绝。</P
    >
    <P>以下情况下，根据相关法律法规的要求，网案将不会响应您的请求：</P>
    <P>1、与国家安全、国防安全有关的；</P>
    <P>2、与公共安全、公共卫生、重大公共利益直接相关的；</P>
    <P>3、与犯罪侦查、起诉、审判和执行判决等直接有关的；</P>
    <P>4、有充分证据表明您存在主观恶意或滥用权利的；</P>
    <P>5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</P>
    <p>6、 涉及商业秘密的。</p>
    <p>六、网案如何处理未成年人的个人信息</p>
    <p>网案至少将以以下方式保护未成年人的个人信息：</p>
    <p>
      1、网案不允许未成年人、不具有独立的完全的民事行为能力民事权利能力的人在本系统创建账户，除非依本政策、《服务协议》的约定而操作。如您是未成年人，您必须征得您父母或监护人的同意，同时您父母或监护人阅读并同意本政策的前提下，您可自愿的向网案提供您的个人信息，授权网案依法依约使用、共享、转让您的个人信息。
    </p>
    <p>
      2、如果您是未成年人，您父母或监护人在使用本系统时和/或后，主动向网案提供您的个人信息，同时授权了网案依法依约使用、共享、转让您的个人信息。
    </p>
    <p>
      3、对于经父母或监护人同意使用网案的产品或服务而收集未成年人个人信息的情况，网案只会在法律允许、服务或监护人明确同意或者为保护未成年人所必要的情况下使用、共享、转让或披露此信息。
    </p>
    <p>
      4、若网案发现在未事先获得父母或监护人同意的情况下收集了未成年人的个人信息，将会设法尽快删除相关数据信息。
    </p>
    <p>在满足以上条件下，网案会依法依约收集、使用、共享、转让您的个人信息。</p>
    <p>七、个人信息的跨界传输</p>
    <p>
      网案在中华人民共和国境内运营中收集和产生的个人信息，将在境内使用、共享、转让、披露您的个人信息。但若进行跨境传输会依照如下方式进行：
    </p>
    <p>
      1、符合法律法规的要求（若有），如：国家网信部门会同国务院有关部门制定的办法和相关标准进行安全评估并符合其要求；
    </p>
    <p>2、符合境外上市地法律法规要求和境内关联公司控制协议及其他协议的要求；</p>
    <p>3、取得您的明示授权；</p>
    <p>4、您通过互联网进行跨境交易等个人主动行为。</p>
    <p>针对以上情形，网案会确保依据本政策对您的个人信息提供足够的保护。</p>
    <p>
      您注意，您签署了本政策即代表您尊重网案与关联公司签署的控制协议及其他协议有关您的要求；您需要配合网案另行签署授权的协议和/或其他信息传输的协议（若需要）。
    </p>
    <p>八、本政策的更新</p>
    <p>
      为了给您提供更好的服务，网案的隐私政策可能会依照法律法规的要求和实际发展需要而变更。若发生本政策的变更，网案会另行在网案家教APP客户端（家长版/教师版）和/或网站，发布公示新的《隐私政策》，另行取得您的同意，未经您明确同意，网案不会限制您按照本政策所应享有的权利。
    </p>
    <p>网案更新本政策的事由包括但不限于以下情形：</p>
    <p>
      1、法律法规、国家标准等发生变化，本政策依法需要作出更新；或本政策依照现有法律法规、国家标准作出完善补充的；
    </p>
    <p>
      2、网案的服务模式发生重大变化，本政策公示的收集、使用、共享、转让、披露内容、目的、范围等不能满足实际需求的；
    </p>
    <p>3、网案的控制权等方面发生重大变化，如并购、重组、上市等引起的；</p>
    <p>4、本政策已公示的共享、转让、披露对象发生重大变化的；</p>
    <p>5、网案依法作出的个人信息安全评估报告表明个人信息安全存在高风险的。</p>
    <p>
      若您不同意本政策的任何变更、对新的《隐私政策》有任何疑惑、不解，您都应停止签署新的《隐私政策》，停止使用本系统、本服务。您注意，网案发布公示新的《隐私政策》后，您继续登录/签署，即表示新的《隐私政策》对您发生法律约束力。
    </p>
    <p>九、如何联系网案</p>
    <p>您可在以下任何情形下联系网案：</p>
    <p>1、您认为、您发现本政策违反中华人民共和国法律法规的；</p>
    <p>
      2、您认为、您发现网案收集、使用、处理、共享、转让、披露您的个人信息违反中华人民共和国法律法规、国家标准，违反本政策的；
    </p>
    <p>
      3、您对本政策有任何疑问、意见、建议的（但此处的疑问、意见、建议并不代表着您对已签署本政策效力的否认和解约）；
    </p>
    <p>4、您认为、您发现的其他事由的。</p>
    <p>您可通过网案官网”，联系网案举报、申诉。</p>
    <p>
      如果您对网案的回复不满意，特别是您认为网案有关您个人信息的行为损害了您的合法权益，您还可以通过向被告住所地有管辖权的法院提起诉讼等司法途径解决。
    </p>
    <p>厦门网案科技有限公司</p>
    <p style="text-align: right">更新生效时间2024年07月13日</p>
  </div>
</template>
<style scoped>
.info {
  width: 90%;
  margin: 0 5%;
}
h2 {
  text-align: center;
}
</style>
