import { createRouter, createWebHashHistory } from "vue-router";
import Privacy from '../views/Privacy.vue'
import MobileTerms from '../views/MobileTerms.vue'
import UserAgreement from '../views/UserAgreement.vue'
// import Detail from '../views/Detail.vue'
const routes = [
  {
    path: '/Privacy',
    name: Privacy,
    component: Privacy
  },
  {
    path: '/MobileTerms',
    component: MobileTerms
  },
  {
    path: '/UserAgreement',
    component: UserAgreement
  },
  // {
  //   path: '/detail',
  //   component: Detail
  // },
  {
    path: '/',
    redirect: '/Privacy'
  }
]
const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
